import { Component, ElementRef, HostBinding, OnInit } from '@angular/core';
import { finalize, tap } from 'rxjs';
import { TmBookingApi } from 'src/lib/repository/temairazu/tm-booking.api';
import { TmBookingDetail } from 'src/lib/repository/temairazu/tm-booking.model';
import { TmPlan } from 'src/lib/repository/temairazu/tm-plan.model';
import { TmRoomplanApi } from 'src/lib/repository/temairazu/tm-roomplan.api';
import { DialogService } from '../../../services/dialog.service';
import { DialogRef } from '../../dialog-ref';
import { DialogAbstract } from '../../dialog.abstract';
import { SharedModule } from '../../shared/shared.module';
import { TmCancelPolicyTableComponent } from '../tm-cancel-policy-table/tm-cancel-policy-table.component';

/**
 * 테마이라즈 예약 취소 모달 데이터
 */
export interface MyRsrvCancelData {
  mrhstId: number;
  tmBooking: TmBookingDetail;
}

/**
 * 테마이라즈 예약 취소 모달 컴포넌트
 */
@Component({
  selector: 'app-tm-booking-cancel-modal',
  templateUrl: './tm-booking-cancel-modal.component.html',
  styleUrls: ['./tm-booking-cancel-modal.component.scss'],
  standalone: true,
  imports: [SharedModule, TmCancelPolicyTableComponent],
})
export class TmBookingCancelModalComponent
  extends DialogAbstract
  implements OnInit
{
  override dialogRef!: DialogRef<this, boolean, MyRsrvCancelData>;
  /**
   * 투어니티 시설 식별번호
   */
  mrhstId!: number;

  /**
   * 테마이라즈 예약
   */
  tmBooking!: TmBookingDetail;

  /**
   * 취소 수수료
   */
  cancelFee!: number;

  /**
   * 로딩 여부
   */
  isLoading = false;

  /**
   * 일본어 페이지임을 표현
   */
  @HostBinding('lang') lang = 'ja';

  /**
   * 번역 사용
   */
  @HostBinding('translate') translate = 'yes';

  constructor(
    protected override elementRef: ElementRef<HTMLElement>,
    private dialogService: DialogService,
    private roomplanService: TmRoomplanApi,
    private tmBookingService: TmBookingApi,
  ) {
    super(elementRef);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.mrhstId = this.dialogRef.data!.mrhstId;
    this.tmBooking = this.dialogRef.data!.tmBooking;

    if (!this.mrhstId || !this.tmBooking) {
      this.dialogRef.close();
    }

    this.setCancelPolicy();
    this.setCancelFee();
  }

  /**
   * 닫기 클릭시
   */
  onCloseClick(): void {
    this.dialogRef.close();
  }

  /**
   * 예약 취소 클릭시
   */
  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

  /**
   * 취소 규정 입력
   */
  private setCancelPolicy(): void {
    this.isLoading = true;
    const params = { plan_id: this.tmBooking.PlanId };

    this.roomplanService
      .getPlanList(this.mrhstId, params)
      .pipe(
        tap(({ Result, Plans }) => {
          if (Result.Code !== 200) {
            this.dialogService
              .alert(
                '취소 수수료 정보를 취득하지 못했습니다. 숙소에 문의해주세요.',
              )
              .subscribe();

            this.dialogRef.close();

            return;
          }

          const plan: TmPlan = Plans[0];
        }),
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe();
  }

  /**
   * 취소료 입력
   */
  private setCancelFee(): void {
    const { BookingId, ModifyKey } = this.tmBooking;
    const params = {
      booking_id: BookingId,
      modify_key: ModifyKey,
    };

    this.tmBookingService
      .getCancelFee(this.mrhstId, params)
      .pipe(
        tap(({ Result, CancellationFee }) => {
          if (Result.Code !== 200) {
            this.dialogService
              .alert(
                '취소 수수료 정보를 취득하지 못했습니다. 숙소에 문의해주세요.',
              )
              .subscribe();

            this.dialogRef.close();

            return;
          }

          this.cancelFee = CancellationFee;
        }),
      )
      .subscribe();
  }
}
