import { OverlayModule } from '@angular/cdk/overlay';
import { Component, HostBinding } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { catchError, filter, mergeMap, of, tap, throwError } from 'rxjs';
import { AuthService } from 'src/lib/auth/auth.service';
import { BookingApi } from 'src/lib/repository/booking/booking.api';
import { IBooking } from 'src/lib/repository/booking/booking.model';
import { TemairazuPicture } from 'src/lib/repository/temairazu/temairazu.model';
import { TmBookingApi } from 'src/lib/repository/temairazu/tm-booking.api';
import {
  TmBookingDetail,
  TmBookingResponse,
} from 'src/lib/repository/temairazu/tm-booking.model';
import {
  TmHotel,
  TmPlan,
  TmRoom,
} from 'src/lib/repository/temairazu/tm-plan.model';
import { TmRoomplanApi } from 'src/lib/repository/temairazu/tm-roomplan.api';
import { TmTopApi } from 'src/lib/repository/temairazu/tm-top.api';
import { BrandService } from 'src/lib/services/brand.service';
import { Utils } from 'src/lib/utils';
import { DialogService } from '../../../services/dialog.service';
import { LoginComponent } from '../../dialog/login/login.component';
import { GoogleMapModule } from '../../google-map/google-map.module';
import { ImgGridComponent } from '../../img-grid/img-grid.component';
import { SharedModule } from '../../shared/shared.module';
import {
  MyRsrvCancelData,
  TmBookingCancelModalComponent,
} from '../tm-booking-cancel-modal/tm-booking-cancel-modal.component';
import { TmCancelPolicyTableComponent } from '../tm-cancel-policy-table/tm-cancel-policy-table.component';

/**
 * 테마이라즈 예약 상태
 */
export type TmBookingStatus = 'paying' | 'complete' | 'canceled';

/**
 * 테마이라즈 예약 상세 컴포넌트
 */
@Component({
  selector: 'app-tm-booking-detail',
  templateUrl: './tm-booking-detail.component.html',
  styleUrls: ['./tm-booking-detail.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    GoogleMapModule,
    OverlayModule,
    FormsModule,
    TmCancelPolicyTableComponent,
    TmBookingCancelModalComponent,
    ImgGridComponent,
  ],
})
export class TmBookingDetailComponent {
  /**
   * 투어니티 예약 식별번호
   */
  bookingId!: number;

  /**
   * 투어니티 시설 식별번호
   */
  mrhstId!: number;

  /**
   * 테마이라즈 예약 조회 키
   */
  authKey!: string;

  /**
   * 투어니티 예약 상세
   */
  booking!: IBooking;

  /**
   * 테마이라즈 호텔
   */
  hotel!: TmHotel;

  /**
   * 테마이라즈 객실
   */
  room!: TmRoom;

  /**
   * 테마이라즈 플랜
   */
  plan!: TmPlan;

  /**
   * 테마이라즈 예약 상세
   */
  tmBookingDetail!: TmBookingDetail;

  /**
   * 테마이라즈 예약 상태
   */
  tmBookingStatus?: TmBookingStatus;

  /**
   * 테마이라즈 예약 결제 URL
   */
  paymentUrl?: string;

  /**
   * 숙박 일수
   */
  stayCount!: number;

  /**
   * 이미지 URL 목록
   */
  imgUrlList: string[] = [];

  /**
   * 이미 체크아웃 했는지 (종료 행사) 여부
   */
  isAlreadyCheckOut = false;

  /**
   * 브랜드명
   */
  brandNm: string;

  /**
   * 일본어 페이지임을 표현
   */
  @HostBinding('lang') lang = 'ja';

  /**
   * 번역 사용
   */
  @HostBinding('translate') translate = 'yes';

  constructor(
    private bookingApi: BookingApi,
    private tmBookingService: TmBookingApi,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private tmTopApi: TmTopApi,
    private tmRoomplanApi: TmRoomplanApi,
    private brandService: BrandService,
  ) {
    // 브랜드명
    this.brandNm = this.brandService.brand.brandNm!;

    const { params, queryParams } = this.activatedRoute.snapshot;
    const { mrhstId, bookingId } = params;
    const { auth_key: authKey } = queryParams;

    if (bookingId) {
      this.bookingId = bookingId;
      this.setDataByBooking();
    } else if (mrhstId && authKey) {
      this.mrhstId = mrhstId;
      this.authKey = encodeURIComponent(authKey);
      this.setDataByAuthKey();
    } else {
      this.kickUserWhenHistoryNotFound();
    }
  }

  /**
   * 결제 클릭시
   */
  onPayClick(): void {
    if (!this.paymentUrl) {
      return;
    }

    window.location.href = this.paymentUrl;
  }

  /**
   * 취소 클릭시
   */
  onCancelClick(): void {
    if (
      this.tmBookingDetail?.CancelDeadline &&
      new Date() >= new Date(this.tmBookingDetail?.CancelDeadline)
    ) {
      this.dialogService
        .alert(this.translateService.instant('bookingCancelDeadlinePassed'))
        .subscribe();
      return;
    }

    if (this.tmBookingStatus === 'paying') {
      this.dialogService
        .alert(
          this.translateService.instant(
            'tm.bookingInPaymentCannotChangeOrCancel',
          ),
        )
        .subscribe();
      return;
    }

    let login$ = of(true);

    if (!this.authService.account) {
      login$ = this.dialogService
        .open(LoginComponent)
        .onDialogClosed.pipe(filter((ok) => ok));
    }

    const data: MyRsrvCancelData = {
      mrhstId: this.mrhstId,
      tmBooking: this.tmBookingDetail,
    };

    login$
      .pipe(
        // 로그인 됐으면
        filter((ok) => ok),
        // 취소 다이얼로그
        mergeMap(
          () =>
            this.dialogService.open(TmBookingCancelModalComponent, {
              width: '343px',
              data,
            }).onDialogClosed,
        ),
        // 취소 클릭했으면
        filter((ok) => ok),
        // 취소
        mergeMap(() =>
          this.bookingApi.cancelBooking(this.bookingId, this.booking),
        ),
        // 안내
        mergeMap(() => {
          return this.dialogService.alert(
            this.translateService.instant('msg.canceled'),
          );
        }),
        tap(() => {
          window.location.reload();
        }),
        catchError((error) => {
          if (error?.message) {
            this.dialogService
              .openErrorAlert('MSG.ERROR', error?.message)
              .subscribe();
          } else {
            this.dialogService
              .alert(this.translateService.instant('tm.bookingCancelFailed'))
              .subscribe();
          }

          return throwError(() => error);
        }),
      )
      .subscribe();
  }

  /**
   * 예약목록에서 상세 선택하여 접근시 이용
   */
  private setDataByBooking(): void {
    this.bookingApi
      .findItem(this.bookingId)
      .pipe(
        tap((booking) => this.setBooking(booking)),
        mergeMap(() => {
          const tmBookingInfo = Utils.getParsedJson(
            this.booking.tmBookingInfoJson,
            {},
          );

          const { MailAddress, BookingId } = tmBookingInfo;

          return this.tmBookingService.getBooking(
            this.mrhstId,
            BookingId,
            MailAddress,
          );
        }),
        tap((tmBooking) => this.setTmBookingDetail(tmBooking)),
        tap(() => this.checkBookingAndTmBookingSame()),
      )
      .subscribe();
  }

  /**
   * 테마이라즈 예약 완료 이메일 링크에서 접근시 이용
   */
  private setDataByAuthKey() {
    this.tmBookingService
      .getBookingWithAuthKey(this.mrhstId, this.authKey)
      .pipe(
        tap((tmBooking) => this.setTmBookingDetail(tmBooking)),
        mergeMap(() =>
          this.bookingApi.findBookingByTmBookingId(
            this.tmBookingDetail.BookingId,
          ),
        ),
        tap((booking) => {
          this.setBooking(booking);
          this.checkBookingAndTmBookingSame();
        }),
      )
      .subscribe();
  }

  /**
   * 투어니티 예약 상세 데이터 입력
   */
  private setBooking(booking: IBooking): void {
    this.mrhstId = booking.scheduleList?.[0]?.hotel?.mrhst?.id!;

    if (!this.mrhstId) {
      this.kickUserWhenHistoryNotFound();
      return;
    }

    this.booking = booking;
    this.bookingId = booking.id!;
    this.isAlreadyCheckOut = dayjs(booking.returnDate).isBefore(dayjs());
    const tmBookingInfo = Utils.getParsedJson(booking.tmBookingInfoJson, {});
    this.paymentUrl = tmBookingInfo.PaymentUrl;
    this.stayCount = dayjs(booking.returnDate).diff(booking.startDate, 'day');
  }

  /**
   * 테마이라즈 예약 상세 데이터 입력
   */
  private setTmBookingDetail(tmBooking: TmBookingResponse): void {
    const { Bookings, Result } = tmBooking;

    if (Result.Status !== 'OK') {
      this.kickUserWhenHistoryNotFound(Result.Message);
      return;
    }

    [this.tmBookingDetail] = Bookings;
    this.tmBookingStatus = this.getStatus(this.tmBookingDetail);

    this.setTemairazuData(
      this.tmBookingDetail.RoomId,
      this.tmBookingDetail.PlanId,
    );
  }

  /**
   * 에러시 페이지 뒤로가기
   */
  private kickUserWhenHistoryNotFound(
    message = this.translateService.instant('tm.bookingHistoryNotFound'),
  ): void {
    this.dialogService.alert(message).subscribe(() => window.history.back());
  }

  /**
   * 투어니티 예약과 테마이라즈 예약이 동일한지 비교, 다르면 뒤로가기
   */
  private checkBookingAndTmBookingSame(): void {
    if (this.booking.tmBookingId !== this.tmBookingDetail.BookingId) {
      this.kickUserWhenHistoryNotFound();
    }
  }

  /**
   * 테마이라즈 데이터 설정
   */
  private setTemairazuData(roomId: number, planId: number): void {
    this.tmTopApi
      .getHotel(this.mrhstId)
      .pipe(
        tap(({ Hotel }) => {
          this.hotel = Hotel;

          const tmPictureList: TemairazuPicture[] = [
            ...this.tmBookingDetail.PlanPictures,
            ...this.tmBookingDetail.RoomPictures,
            ...this.hotel.Pictures,
          ];

          this.imgUrlList = tmPictureList.map(({ Url }) => Url);
        }),
      )
      .subscribe();

    this.tmRoomplanApi
      .getRoomList(this.mrhstId, { room_id: roomId })
      .pipe(
        tap(({ Rooms }) => {
          [this.room] = Rooms;
        }),
      )
      .subscribe();

    this.tmRoomplanApi
      .getPlanList(this.mrhstId, { plan_id: planId })
      .pipe(
        tap(({ Plans }) => {
          [this.plan] = Plans;
        }),
      )
      .subscribe();
  }

  /**
   * 테마이라즈 예약 상태 획득
   */
  private getStatus(tmBookingDetail: TmBookingDetail): TmBookingStatus {
    const { Cancelled, Payment, Unprocessed } = tmBookingDetail;

    if (Cancelled === 1) {
      return 'canceled';
    }

    if (Payment === 1 && Unprocessed === 1) {
      return 'paying';
    }

    return 'complete';
  }
}
