<div
  class="toolbar-wrapper sticky-top"
  [ngClass]="{
    'no-shadow': isScrollTop,
    'no-border': !showBorder,
    transparent: headerTransparent
  }"
  [class]="'type-' + headerType"
>
  <nav class="navbar navbar-expand-md">
    <!-- 브랜드 로고 (데스크톱) -->
    <a class="navbar-brand logo img-btn desktop" routerLink="/home">
      <img class="company-logo" [src]="logoImgUrl" />
    </a>

    <!-- 브랜드 로고 (모바일) -->
    <!-- headerTitle 설정 없으면 로고 표시 -->
    @if (!headerTitle) {
      <a
        class="navbar-brand logo img-btn mobile"
        routerLink="/home"
        [ngClass]="{ 'no-side-menu': !showSideMenu }"
      >
        <img class="company-logo" [src]="logoImgUrl" />
      </a>
    } @else {
      <!-- 모바일 해더 제목 -->
      <!-- headerTitle 설정 있으면 표시 -->
      <div class="header-title-wrapper">
        <!-- 뒤로가기 버튼 -->
        <button class="btn-back" (click)="onBackButtonClick()">
          <i class="bi bi-arrow-left-short"></i>
        </button>
        <!-- 해더 제목 -->
        <span
          class="header-title"
          [ngClass]="{ 'no-side-menu': !showSideMenu }"
          >{{ headerTitle! | translate }}</span
        >
      </div>
    }

    <!-- 로고 및 메뉴 사이 간격-->
    <div class="spacer">
      @if (headerType === '2' || headerType === '3') {
        <ng-container *ngTemplateOutlet="menu"> </ng-container>
      }
    </div>

    <span class="test-env" *ngIf="!isProd">{{ 'Test' | translate }}</span>

    <ul class="navbar-nav">
      <!-- 회원 정보 -->
      @if (isLoggedIn && userInfo) {
        <li class="user-info">
          @if (showUserClass) {
            <span class="user-class">({{ userInfo.customerClass }})</span>
          }
          <!-- 회원 명 -->
          <span class="name">
            {{ userInfo.userNm ?? userInfo.userId }}
            {{ 'sir' | translate }}
          </span>
          <!-- 잔여 포인트 -->

          <ng-container *feature="'userInfo_point'">
            <span class="point" translate="no">
              {{ userCard?.prpayAmt | number }}P
            </span>
            <!-- 정보 갱신 버튼 -->
            <button
              type="button"
              class="btn refresh"
              (click)="onRefreshInfoButtonClick()"
            >
              <i class="bi bi-arrow-clockwise"></i>
            </button>
          </ng-container>
        </li>
      }

      <!-- 메뉴 -->
      <li>
        <div class="button-wrapper">
          @if (isTextMenu()) {
            @if (!isLoggedIn) {
              <a class="text-menu" (click)="onLoginButtonClick()">{{
                'login' | translate
              }}</a>
              <a class="text-menu" (click)="onRegisterButtonClick()">{{
                'Register' | translate
              }}</a>
            }

            <a
              class="text-menu"
              *ngIf="isLoggedIn"
              (click)="onLogoutButtonClick()"
              >{{ 'BUTTON.logout' | translate }}</a
            >
          } @else {
            <!-- 예약 내역 버튼 -->
            <button class="btn-menu" type="button">
              <a
                class="img-caption-btn"
                (click)="onMenuButtonClick('/my-info')"
              >
                <i class="bi bi-list-ul"></i>
                @if (isEtcBrand()) {
                  <span class="caption">{{ 'orderHist' | translate }}</span>
                } @else {
                  <span class="caption">{{ 'myInfo' | translate }}</span>
                }
              </a>
            </button>

            <!-- 마이페이지 아이콘 -->
            <!-- 회원 기능 사용 -->
            <ng-container *feature="'userInfo'">
              <!-- 로그인 -->
              @if (isLoggedIn) {
                <div class="dropdown">
                  <button
                    class="btn-menu"
                    type="button"
                    data-bs-toggle="dropdown"
                  >
                    <a class="img-caption-btn">
                      <i class="bi bi-lock-fill"></i>

                      <span class="caption">
                        {{ 'myPage' | translate }}
                      </span>
                    </a>
                  </button>

                  <!-- 마이페이지 드롭다운 -->
                  <ul class="dropdown-menu dropdown-menu-end">
                    <li>
                      <a class="dropdown-item" routerLink="/my/change-info'">
                        {{ 'profile' | translate }}
                      </a>
                    </li>

                    <ng-container *homeScreen="'showMembershipMenuFlg'">
                      <ng-container *feature="'userInfo_point'">
                        <li>
                          <a class="dropdown-item" routerLink="/my/prpay">
                            {{ 'Membership' | translate }}
                          </a>
                        </li>
                      </ng-container>
                    </ng-container>
                  </ul>
                </div>
              } @else {
                <button class="btn-menu" type="button">
                  <a class="img-caption-btn" (click)="onLoginButtonClick()">
                    <i class="bi bi-unlock"></i>

                    <span class="caption">
                      {{ 'login' | translate }}
                    </span>
                  </a>
                </button>
              }
            </ng-container>
          }

          <!-- 언어 변경 버튼 -->
          <div class="dropdown">
            @if (canChangeLanguage) {
              <button
                type="button"
                class="btn-menu language"
                data-bs-toggle="dropdown"
              >
                <a class="img-caption-btn">
                  <i class="bi bi-globe"></i>

                  <span class="caption">{{
                    'GUIDE.changeLang' | translate
                  }}</span>
                </a>
              </button>
            }

            <!-- 변경 버튼 클릭 시 표시될 언어 선택 메뉴 -->
            <ul class="dropdown-menu dropdown-menu-end">
              @for (language of languageList; track $index) {
                <li>
                  @if (isSupportedLanguage(language.code)) {
                    <button
                      type="button"
                      class="dropdown-item"
                      translate="no"
                      (click)="onLanguageMenuButtonClick(language.code)"
                    >
                      {{ language.name }}
                    </button>
                  }
                </li>
              }
            </ul>
          </div>
        </div>
      </li>
    </ul>

    <!-- 모바일 사이드 메뉴 열기 버튼 -->
    <button
      class="btn-icon btn-side-menu"
      type="button"
      (click)="onSideMenuButtonClick()"
    >
      <!-- <span class="navbar-toggler-icon"></span> -->
      <i class="bi bi-list"></i>
    </button>
  </nav>

  @if (headerType !== '2' && headerType !== '3') {
    <ng-container *ngTemplateOutlet="menu"> </ng-container>
  }

  <ng-template #menu>
    <!-- 메뉴 목록 -->
    <div class="menu-list-wrapper">
      <nav class="menu-list">
        <div class="menu-tabbar">
          <ul class="nav">
            <!-- 메뉴 버튼 -->
            @for (menu of menuListNew; track $index) {
              @if (
                isMenuVisibleByBreakpoint(menu.breakpointToShow) &&
                menu.type !== 'notice'
              ) {
                <li class="nav-item">
                  <!-- 메뉴 타입 'URL'일때 동작 -->
                  @if (menu.type === 'url') {
                    <!-- URL 주소가 '/'으로 시작하면 내부 주소로 판단 -->
                    @if (menu.url?.startsWith('/')) {
                      <!-- 내부 링크 -->
                      <a
                        class="nav-link menu-item"
                        [routerLink]="menu.url"
                        routerLinkActive="on"
                      >
                        {{ menu.title! }}
                      </a>
                    } @else {
                      <!-- 외부 링크 -->
                      <a
                        class="nav-link menu-item external-link"
                        target="_blank"
                        [href]="menu.url"
                      >
                        {{ menu.title! }}
                        <i class="bi bi-box-arrow-up-right"></i>
                      </a>
                    }
                  } @else {
                    <!-- 'URL'외 타입들은 메뉴 페이지에서 처리 -->
                    <a
                      class="nav-link menu-item"
                      [routerLink]="['menu', menu.id]"
                      routerLinkActive="on"
                    >
                      {{ menu.title! }}
                    </a>
                  }
                </li>
              }
            }

            <!-- TODO: 동적 설정으로 변경 -->
            <li class="nav-item">
              <a
                class="nav-link menu-item"
                routerLink="/notice"
                routerLinkActive="on"
              >
                {{ 'notice' | translate }}
              </a>
            </li>

            @if (headerType === '3') {
              <li class="nav-item">
                <a
                  class="nav-link menu-item"
                  routerLink="/my-info"
                  routerLinkActive="on"
                >
                  @if (isEtcBrand()) {
                    {{ 'orderHist' | translate }}
                  } @else {
                    {{ 'myInfo' | translate }}
                  }
                </a>
              </li>

              <li class="nav-item">
                <a
                  class="nav-link menu-item"
                  routerLink="/my/change-info"
                  routerLinkActive="on"
                >
                  {{ 'profile' | translate }}
                </a>
              </li>

              <ng-container *homeScreen="'showMembershipMenuFlg'">
                <ng-container *feature="'userInfo_point'">
                  <li class="nav-item">
                    <a
                      class="nav-link menu-item"
                      routerLink="/my/prpay"
                      routerLinkActive="on"
                    >
                      {{ 'Membership' | translate }}
                    </a>
                  </li>
                </ng-container>
              </ng-container>
            }
          </ul>
        </div>
      </nav>
    </div>
  </ng-template>
</div>
