import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import {
  EMPTY,
  Observable,
  Subject,
  catchError,
  filter,
  finalize,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

let isRefreshing = false;

const recall: Subject<boolean> = new Subject();

/**
 * 액세스 토큰 담긴 요청 획득
 */
const getTokenAddedRequest = (
  authService: AuthService,
  request: HttpRequest<unknown>,
): HttpRequest<unknown> => {
  const tokenAddedRequest: HttpRequest<unknown> = request.clone({
    setHeaders: {
      Authorization: `${authService.tokenType} ${authService.accessToken}`,
    },
  });

  return tokenAddedRequest;
};

/**
 * 토큰 갱신 후 재요청
 */
const getRefreshedRequest = (
  authService: AuthService,
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<any> => {
  // 갱신중일땐
  if (isRefreshing) {
    // 갱신 완료됐을때 대기중인 요청 실행. 순서는 보장할수 없음.
    return recall.pipe(
      filter((ready) => ready),
      // 한번만 실행. 없으면 토큰 갱신할때 이전에 실행했던 API들이 호출될수 있음.
      take(1),
      // 같은 옵저버블이 여러번 발생하면 마지막것만 실행
      switchMap(() => {
        return next(getTokenAddedRequest(authService, request));
      }),
    );
  }

  if (!authService.refreshToken) {
    // 갱신 토큰 없음
    authService.logout();
    return EMPTY;
  }

  // 갱신 시작
  isRefreshing = true;
  recall.next(false);

  return authService.getRefreshAuth$().pipe(
    // 같은 옵저버블이 여러번 발생하면 마지막것만 실행
    switchMap(() => {
      // 갱신 성공
      return next(getTokenAddedRequest(authService, request));
    }),
    tap(() => {
      // 대기중인 요청들 실행
      recall.next(true);
    }),
    catchError((error) => {
      // TODO: 갱신 실패시 로그아웃 메시지 검토
      authService.logout('invalid_token', true);
      throw error;
    }),
    finalize(() => {
      // 갱신 종료
      isRefreshing = false;
    }),
  );
};

/**
 * 인증 인터셉터 Fn
 */
export const authInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  // 토큰 전송하지 말아야 할 주소
  const hasExcluded = [`${environment.apiServerUrl}/oauth`].some(
    (excluded) => req.url.indexOf(excluded) >= 0,
  );

  // 토큰 전송하지 말아야 할 주소이면
  if (hasExcluded) {
    // 토큰 없이 요청
    return next(req);
  }

  const authService = inject(AuthService);

  if (!authService.accessToken && !authService.refreshToken) {
    // 토큰 없이 요청
    return next(req);
  }

  return next(getTokenAddedRequest(authService, req)).pipe(
    catchError((error) => {
      // 통신 응답이 401이면
      if (error instanceof HttpErrorResponse && error.status === 401) {
        // 토큰 갱신 후 재요청
        return getRefreshedRequest(authService, req, next);
      }

      throw error;
    }),
  );
};
